.homePageContainer {
  margin: 0 auto;
}

.homePageContainer {
  padding: 50px 75px;
  max-width: 1110px;
  margin: 0 auto;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 10vw 20vw;
  flex: 1;
}

.title p {
  font-size: 21px;
  font-weight: 600;
  font: "Degular Display";
  font-family: var(
    --zds-typography-heading,
    "Degular",
    Helvetica,
    arial,
    sans-serif
  );
}

.titleRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.tableContainer {
  background-color: #fff;
  border: 1px solid #d9d9d9;
  padding: 15px 30px;
}

.buttonWrapper {
  display: flex;
  gap: 10px;
  align-items: center;
}

.noAccessWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 20px;
  align-items: center;
}

.noAccessWrapperLine {
  display: flex;
  justify-content: center;
}

.buttonContent {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  gap: 10px;
}
