.pageContainer {
  display: flex;
  gap: 30px;
  overflow: auto;
  width: 100vw;
  align-items: stretch;
  padding: 0px;
}

.header {
  position: fixed;
  top: 0;
  height: 70px;
  width: 100vw;
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--zds-colors-neutral-400);
}

.zapierLogo {
  padding-left: 18px;
}

.zapierLogo > svg {
  height: 70px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 10vw 20vw;
  flex: 1;
}

.title {
  font-family: var(
    --zds-typography-heading,
    "Degular",
    Helvetica,
    arial,
    sans-serif
  );
  font-size: var(--zds-typography-pageheader7-fontsize, 34px);
  line-height: var(--zds-typography-pageheader7-lineheight, 40px);
  font-weight: var(--zds-typography-semibold-weight, 600);
  letter-spacing: var(--zds-typography-large-letter-spacing, 1px);
}

.titleWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.titleRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
