.root {
  display: grid;
  margin: 20vh auto;
  max-height: 30vh;
  max-width: 1400px;
  padding-bottom: 30px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 30px;
  @media (min-width: 660px) {
    paddingbottom: 78px;
    paddingtop: 70px;
  }
}

.subtitle {
  display: inline-grid;
  justify-content: center;
  margin-top: 30px;
  font-family: Inter, sans-serif;
  font-weight: 400;
  @media (min-width: 660px) {
    margin-top: 40px;
  }
}

.buttonWrapper {
  display: inline-grid;
  justify-content: center;
  margin-top: 30px;
  @media (min-width: 660px) {
    margin-top: 40px;
  }
}

.heading {
  color: #2d2e2e;
  text-align: center;
  font-size: 82px;
  /* font-family: "Degular Display", sans-serif; */
}
