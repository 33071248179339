.ownerCell {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headerCell {
  font-weight: 900;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.dropdownContainer {
  margin: 14px 0px 20px 0px;
  width: fit-content;
  min-width: 150px;
}

.paginatorContainer {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.emptyState {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}
