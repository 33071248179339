.form {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.title {
  font-family: Inter;
  font-size: 18px;
  font-weight: 600;
  line-height: 31px;
  text-align: left;
  color: var(--zds-colors-neutral-700);
  margin-bottom: 15px;
}

.subtitle {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  border: 1px solid var(--zds-colors-neutral-400);
  padding: 15px;
  border-radius: 10px 10px 0 0;
}

.chipText {
  background: var(--zds-colors-neutral-700);
  color: var(--zds-colors-neutral-100);
  padding: 8px;
  border-radius: 7px;
}

.sampleDataContainer {
  padding: 15px;
  border-radius: 0 0 10px 10px;
  border: 1px solid var(--zds-colors-neutral-400);
  border-top: none;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.webhookWrapper {
  display: flex;
  flex-direction: column;
}

.webhookLabel {
  display: flex;
  align-items: center;
}

.formatButtonWrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  padding-right: 10px;
  padding-bottom: 10px;
}

.customJSONContainer {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 10px;
}

.monacoEditorWrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.monacoEditorContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
