.subtitle {
  font-size: 16px;
  line-height: 28px;
  color: #2d2e2e;
  font-weight: 600;
}

.mutedInfo {
  color: #6f6f6f;
  font-family: Inter;
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}

.logsTitleBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  border-top: 1px solid var(--zds-colors-neutral-400);
  padding: 10px 0px;
}

.logEntry {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #000000;
  gap: 10px;
  font-family: "Inconsolata", monospace;
  font-size: 14px;
  word-wrap: break-word;
  margin: 5px 0px;
}

.logTimeStamp {
  font-size: 14px;
  font-weight: 500;
  flex: 0 0 auto;
  margin: 10px 0px 10px 13px;
}

.logText {
  display: flex;
  align-items: center;
  gap: 8px;
}

.log {
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: break-spaces;
  line-break: anywhere;
}

.logTextBold {
  font-weight: 700;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.statusTag {
  margin: 5px 0px;
}

.logTextWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.actionWrapper {
  margin: 20px 0px;
}

.alertWrapper {
  margin: 20px 0px;
}
.jsonLine {
  width: 100%;
  height: 100%;
}

.installLogs {
  word-wrap: break-word;
}

.installLogsTitle {
  display: flex;
  align-items: center;
  gap: 10px;
}
