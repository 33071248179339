.enableModalForm {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.buttonText {
  display: flex;
  align-items: center;
  gap: 8px;
}

.versionInfoContainer {
  width: 100%;
  margin-top: 16px;
}

.versionInfo {
  padding: 4px 6px;
  border-radius: 5px;
  border: var(--border-width--default, 1px) solid var(--stroke-default, #b5b2aa);
  margin-top: 4px;
}

.modalBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 26px;
}
