.accountConnectionsDrawer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.emptyStateCard {
  border-radius: 5px;
  border: 1px solid var(--neutrals-neutral500, #a8a5a0);
  background: var(--neutrals-neutral100, #fffdf9);
  padding: 20px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accountCard {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 5px;
  padding: 14px;
  border: 1px solid var(--neutrals-neutral500, #a8a5a0);
  background: var(--neutrals-neutral100, #fffdf9);
}

.accountCardContent {
  display: flex;
  justify-content: space-between;
  gap: 3px;
  flex: 1;
  max-width: 100%;
}

.accountCardDetails {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.accountCardFirstLine {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.accountCardTitle {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.accountCardTitleIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accountCardTitleText {
  /* These need to be !important to override Zinnia styles. */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.accountCardButtons {
  position: relative;
  min-width: 99.445px; /* Matches the width of the button so that when we update the content to a spinner, it doesn't jump. */
}

.privateConnectionsList {
  padding-left: 20px;
}

.privateAccountCard {
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  padding: 14px;
  gap: 10px;
  border: 1px solid var(--neutrals-neutral500, #a8a5a0);
  background: var(--neutrals-neutral100, #fffdf9);
}

.privateAccountCardTag {
  margin: 10px 0;
}

.privateAccountHeader {
  display: flex;
  align-items: center;
  gap: 5px;
  /* margin-bottom: 10px; */
}
