.heading {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0px;
  text-align: left;
}

.sampleDataWrapper {
  /* border: 1px solid #bbbbbb; */
  padding: 20px 12px;
  margin-bottom: 17px;
  /* border-radius: 4px; */
}

.sampleDataText {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  margin: 5px 0;
}

.recordsHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  padding: 15px;
  background: var(--zds-colors-neutral-200);
  border-bottom: 1px solid var(--zds-colors-neutral-400);
}

.dropdownLabel {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  font-family: Inter;
  padding-bottom: 15px;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0px;
  color: #000000;
  @media (min-width: 768px) {
    flex-direction: row;
    align-items: center;
  }
}

.monacoEditorWrapper {
  position: relative;
  padding: 20px;
  font-size: 12px;
  line-height: 18px;
  flex: 1;
}

.monacoEditorContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.sampleData {
  display: flex;
  background-color: #ffffff; /* Light background */
  flex: 1;
  height: 100%;
  overflow: hidden;
}

.radioButtonsWrapper {
  min-width: fit-content;
  border-right: 1px solid var(--zds-colors-neutral-400);
  overflow-x: hidden;
}

.radioButton {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 90px;
  font-size: 14px;
  transition: 0.3s background-color;
}

.radioButton:hover {
  background-color: #35363a;
}

.radioButtonSelected {
  background-color: #414245;
}

.radioButtonLabel {
  padding: 15px 10px;
  width: 100%;
  gap: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.radioButton {
  border-bottom: 1px solid #cccccc; /* Lighter border */
}

.dropdownLabel,
.code,
.radioButton,
.heading {
  color: #2d2e2e; /* Dark text for light background */
}

.radioButton:hover {
  background-color: #f5f5f5; /* Light hover background */
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.spinnerWrapper {
  width: 100%;
}

.noDataWrapper {
  padding: 20px;
  font-size: 16px;
  line-height: 18px;
  flex: 1;
}

.privateIntegrationHeader {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
}

.webhookHelpText {
  margin-top: 10px;
}

.incompleteTrigger {
  padding: 20px;
  margin-top: 15px;
  text-align: center;
  border: 1px solid var(--zds-colors-neutral-400);
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 3px;
}

.sampleDataRecordsWrapper {
  flex: 1;
  min-height: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.sampleDataDisplayWrapper {
  border: 1px solid var(--zds-colors-neutral-400);
  border-radius: 3px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.sampleDataActions {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
}